<script>
import {Chart} from 'highcharts-vue'
import { chartColors } from '@/helpers'

export default {
   components: {
    Highcharts: Chart
  },
  props: {
   
    noCard: {
      type: Boolean,
      required: false
    },
    instrument: {
      type: Object,
      required: true
    },
    normalized:{
      type: Boolean,
      required: true
    },
    colors:{
      type: Object,
      required: true
    },
    hideChartHint: {
      type: Boolean,
      required: false
    }
  },
  created(){
 
  },
  data() {
    return {
      colorIndex: 0,
      mainChartYAxisLogarithmicType: false
    };
  },
  computed:{
    chartOptions() {
      if(!this.hasData)
        return null;

      let chartSeries = [...this.normalized ?  this.instrument.data.normalizedNetAssetValueGrowthChart || [] : this.instrument.data.netAssetValueGrowthCharts || []];

      if(this.instrument.children && this.instrument.children.length > 0) {
        this.instrument.children.filter(x => x.visible).forEach(child =>{
          var charts = this.normalized ?  child.data.normalizedNetAssetValueGrowthChart || [] : child.data.netAssetValueGrowthCharts || [];
          if(charts.filter(x => x.indexId === child.id).length > 0) {
            var chart = {
              indexId: child.id,
              isLive: false,
              points: [],
              title: child.name
            };
            charts.filter(x => x.indexId === child.id).forEach(childChart =>{
              chart.points = [...chart.points, ...childChart.points];
            });
            chartSeries.push(chart);
          }
          if(charts.filter(x => x.indexId !== child.id).length) {
            chartSeries = [...chartSeries, charts.filter(x => x.indexId !== child.id)];
          }
        });
        //var childrenChart = 
        //chartSeries = [...chartSeries,...this.instrument.children.map(x => this.normalized ?  x.data.normalizedNetAssetValueGrowthChart || [] : x.data.netAssetValueGrowthCharts || [])];
      }

      
      let seriesOptions = [];
      for(let i = 0; i < chartSeries.length; i++){
        
        const source = chartSeries[i];
        const lineWidth =(source.isLive || ((this.instrument.children || []).length > 0 && this.instrument.name === source.title)) ? 4 : 1;
        seriesOptions[i] = {
          name: source.title + (source.isLive ? ' [Live]' : ''),
          label: source.title,
          data: (source.points || []).map(x => [x.timeStamp, x.value]),
          color: source.isLive ? "#7d096d" : this.colors[source.indexId],
          lineWidth: lineWidth
        };
      }

      let opts = {
        credits:{
           enabled: false
        },
        legend:{
          enabled: true
        },
        exporting: {
          buttons: {
              contextButton: {
                  menuItems: [
                  "viewFullscreen", 
                  "printChart", 
                  "separator", 
                  "downloadPNG", 
                  "downloadJPEG", 
                  "downloadPDF", 
                  "downloadSVG",
                  "separator",
                  "downloadXLS",
                  "downloadCSV",
                  "viewData" ]
              }
          }
        },
        rangeSelector: {
            selected: 5
        },
        xAxis: {
          type: 'datetime',
        
          labels: {
              
          },
        
        },
        yAxis: {
          type: this.mainChartYAxisLogarithmicType ? 'logarithmic' : null,
          labels: {
            formatter: function () {
              return (this.value > 0 ? '' : '') + this.value;
            }
          },
          plotLines: [{
            value: 0,
              width: 2,
              color: 'silver'
          }]
        },
        plotOptions: {
          series: {
            showInNavigator: true
          }
        },
        tooltip: {
          pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
          valueDecimals: 2,
          xDateFormat:  '%d/%m/%Y'
        },
        colors: chartColors,
        series: seriesOptions
      };
     
      return opts;
    },
    hasData() {
      return this.instrument.data.netAssetValueGrowthCharts && this.instrument.data.netAssetValueGrowthCharts.length > 0 && this.instrument.data.netAssetValueGrowthCharts.filter(x => x.points.length > 0).length > 0;
    }
  }
};
</script>

<template>
  <div v-if="hasData" :class="{'card cardc8': !noCard}" style="height:100%; ">
    <div  :class="{'card-content': !noCard}">
      <div  :class="{'card-body': !noCard}">
 
        <!-- Header -->
        <div class="row">
          <div class="col" v-if="!noCard">
            <h5 class="card-title">Performance</h5>
          </div>
          <div class="col text-right">
            <b-form-checkbox 
              v-model="mainChartYAxisLogarithmicType" switch class="mr-1" 
              style="display:inline-block;"
            >
    
            </b-form-checkbox>
            Logarithmic Y axis
          </div>
        </div>

        <h6 class="card-subtitle mb-2 mt-1 text-muted" v-if="!hideChartHint">
          <span>* Live Track Record in <b>Bold</b>. This Index is not a benchmark within the meaning of the Benchmarks Regulation issued by ESMA</span>
        </h6>

        <em class="text-muted" v-if="!hasData">No data found...</em>
        
        <highcharts 
          v-if="hasData" 
          :constructorType="'stockChart'" 
          class="hc" 
          :options="chartOptions" 
          ref="chart"
        >
        </highcharts> 

      </div>
    </div>
  </div>
</template>